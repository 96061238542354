import styled from "styled-components"
import { useSession } from "next-auth/react"
import useComponentVisible from "@/components/common/useComponentVisible"
import { useRouter, Link } from "next-translate-routes"
import MenuDesktop from "./mainMenu/menuDesktop"
import MenuMobile from "./mainMenu/menuMobile"

const HeaderNavigation = () => {
  const { data: session } = useSession()

  const router = useRouter()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  return (
    <div>
      <Navbar ref={ref} className="headerBar">
        <MenuDesktop session={session} />
        <MenuMobile session={session} />
      </Navbar>
    </div>
  )
}

export default HeaderNavigation

const Navbar = styled.div`
  padding: 0.5rem 0;
  background-color: white;
  position: relative;
  box-shadow: 0px 4px 12px 0px #1018280f;
  z-index: 1;

  .chevron {
    margin-top: 0.4rem;
  }

  @media (min-width: 992px) {
    .inner {
      padding: 0.5rem 0rem;
    }
  }

  .nav-item {
    :first-child {
      margin-left: 4rem;
    }

    padding: 1rem 3rem 1rem 0;
    margin: auto 0;
  }

  .mobile-menu {
    padding-top: 1.2rem;
    padding-right: 1rem;
    cursor: pointer;
  }

  .nav-button {
    font-size: 1rem;
    padding: 0.7rem 1.4rem;
    margin-top: -5px;
    color: white;
  }

  a {
    color: #333;
    text-decoration: none;

    :hover {
      color: #8a8a8a;
      text-decoration: none;
    }
  }

  .profile-button {
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border: 1px solid #dddddd;
    border-radius: 75px;
    display: flex;

    svg {
      margin-top: 5px;
    }

    .list {
      margin-right: 10px;
    }

    :hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  // dropdown content

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    position: absolute;
    top: 55px;
    left: -140px;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 0.875rem;

      :hover {
        background-color: #f9f9f9;
      }
    }
  }

  .dropdown-listings {
    position: absolute;
    top: 55px;
    left: -140px;
    background-color: white;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 0.875rem;

      :hover {
        background-color: #f9f9f9;
      }
    }
  }

  .mobile {
    .item {
      display: block;
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
      cursor: pointer;

      :hover {
        color: #fb8500;
        border-radius: 5px;
      }
    }
  }
`
