import { Copy, List, X } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link, useRouter } from "next-translate-routes"
import { ClickAwayListener, Fade } from "@mui/material"
import styled from "styled-components"
import { signOut } from "next-auth/react"
import Image from "next/image"
import ValvestLogo from "@/public/logo_valvest.svg"
import { FaRegCopy } from "react-icons/fa"
import { toast } from "react-toastify"
import { FaUserFriends } from "react-icons/fa"
import {
  Bookmarks,
  BoxArrowLeft,
  CashStack,
  Gear,
  HouseCheck,
  Repeat,
} from "react-bootstrap-icons"

const AccountMobile = ({ showMobile, setMobile, session }) => {
  const router = useRouter()

  const copied = translate("account_raf_copied")

  const currentPath = router.pathname

  const isActive = path => (currentPath === path ? "active" : "")

  return (
    <Fade in={showMobile}>
      <MobileMenu>
        <div
          className="inner mobile text-start"
          style={{
            right: showMobile ? 0 : "-100%",
            transition: "right 0.2s ease-in-out",
          }}
        >
          <div className="d-flex ms-3 me-3 mt-2">
            <Image
              src={ValvestLogo}
              alt="valvest Logo"
              className="clickable"
              as="a"
              onClick={() => {
                router.push("/"), setMobile(false)
              }}
              priority
              style={{ marginTop: "2px" }}
              width={160}
            />

            <X
              onClick={() => setMobile(false)}
              className="closeMobile"
              size={30}
              color={"#fb8500"}
            />
          </div>
          <hr />
          <div className=" item pt-0 pb-0 p-4">
            <p className="name mt-0 mb-0">
              {session?.profile && (
                <>
                  {session?.profile?.firstName +
                    " " +
                    session?.profile?.lastName || null}
                </>
              )}
            </p>
            <p className="id">
              ID: {session?.uid.substring(0, 8)}
              <FaRegCopy
                className="ms-1 clickable"
                onClick={() => {
                  navigator.clipboard.writeText(session?.uid.substring(0, 8))
                  toast.success(copied)
                }}
              />
            </p>
          </div>
          <hr className="p-0 mb-3 mt-0" />
          <div className="menu">
            <Link
              href="/account/bookmarks"
              onClick={() => setMobile(false)}
              className={`item ${isActive("/account/bookmarks")}`}
            >
              <Bookmarks />
              {translate("account_submenu_favorites")}
            </Link>
            <Link
              href="/account/portfolio"
              onClick={() => setMobile(false)}
              className={`item ${isActive("/account/portfolio")}`}
            >
              <HouseCheck style={{ marginTop: "-0.2rem" }} />
              {translate("account_submenu_portfolio")}
            </Link>
            <Link
              href="/account/balance"
              onClick={() => setMobile(false)}
              className={`item ${isActive("/account/balance")}`}
            >
              <CashStack />
              {translate("account_submenu_balance")}
            </Link>
            <Link
              href="/account/refer-a-friend"
              onClick={() => setMobile(false)}
              className={`item ${isActive("/account/refer-a-friend")}`}
            >
              <FaUserFriends />
              {translate("account_submenu_raf")}
            </Link>
            <Link
              href="/account/auto-invest"
              onClick={() => setMobile(false)}
              className={`item ${isActive("/account/auto-invest")}`}
            >
              <Repeat />
              {translate("account_submenu_autoinvest")}
            </Link>
            <hr className="p-0 mt-2 mb-2" />
            <Link
              href="/account/settings"
              className={`item ${isActive("/account/settings")}`}
              onClick={() => setMobile(false)}
            >
              <Gear />
              {translate("account_submenu_settings")}
            </Link>
            <div className="referral-box d-flex">
              <FaUserFriends size={20} />
              <div>
                <p className="mb-0 referral-title">
                  {translate("account_dropdown_raf_title")}
                </p>
                <p className="mb-0 mt-1 referral-description">
                  {translate("account_dropdown_raf_description") +
                    " " +
                    session?.referralToken}{" "}
                  <FaRegCopy
                    className="ms-1 clickable"
                    onClick={() => {
                      navigator.clipboard.writeText(session?.referralToken)
                      toast.success(copied)
                    }}
                  />{" "}
                </p>
                <p
                  onClick={() => {
                    router.push("/account/refer-a-friend"), setMobile(false)
                  }}
                  className="mb-0 mt-1 link"
                >
                  {translate("account_dropdown_raf_button")}
                </p>
              </div>
            </div>
            <hr />
            <div className="item" onClick={e => signOut({ callbackUrl: "/" })}>
              <BoxArrowLeft />
              {translate("account_submenu_logout")}
            </div>
          </div>
        </div>
      </MobileMenu>
    </Fade>
  )
}

export default AccountMobile

const MobileMenu = styled.div`
  background: rgba(0, 0, 0, 40%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 9999;
  text-align: left !important;

  .inner {
    width: 100%;
    min-width: 300px;
    background: white;
    position: fixed;
    top: 0px;
    height: 100%;
  }

  .menu .item {
    padding: 0.5rem 1.5rem !important;
    color: rgba(18, 20, 23, 0.75);
    cursor: pointer;
    font-size: 0.95rem;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.active {
      background: rgba(
        0,
        0,
        0,
        0.05
      ); /* Highlighted background for active item */
      font-weight: 500;
    }
  }

  .referral-box {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 10px;
    margin: 1rem;
    background-color: #fceed0;
    color: rgba(18, 20, 23, 0.75) !important;

    .referral-title {
      font-weight: 600;
      font-size: 1rem;
      color: rgba(18, 20, 23, 0.75) !important;
    }

    .referral-description: {
      font-weight: 300;
    }

    .link {
      color: #136fe8;
      font-weight: 300;
      cursor: pointer;
    }
  }

  svg {
    margin-right: 0.75rem;
  }

  .name {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .id {
    margin-top: 0;
    font-size: 0.9rem;
    color: rgba(18, 20, 23, 0.5) !important;
  }

  .closeMobile {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  hr {
    color: #a4a4a4;
  }
`
