import { Avatar } from "@mui/material"
import moment from "moment"
import { CashStack, Dot, Envelope } from "react-bootstrap-icons"
import { MdNotificationsNone } from "react-icons/md"
import { reloadSession } from "@/services/common/helpers"
import React, { useState } from "react"
import axios from "axios"

const NotificationItem = ({ message, session }) => {
  const [showMessage, setMessage] = useState(false)

  const markRead = async item => {
    const readMessages = session.readMessages?.concat(item.id) || []

    const body = {
      readMessages: readMessages,
    }

    await axios
      .put(`/api/users`, body, {
        headers: {
          Authorization: `bearer ${session.jwt}`,
        },
      })
      .catch(error => {
        console.log(error)
      })

    reloadSession()
  }

  return (
    <>
      <div
        key={message.id}
        className="message d-flex"
        onClick={() => {
          if (!session.readMessages?.some(item => item.id === message.id)) {
            markRead(message)

            reloadSession()
          }

          if (message?.type === "update" && message?.link) {
            router.push(message?.link)
          } else if (message?.type === "return") {
            router.push("/account/portfolio")
          } else if (message?.type === "personal") {
            setMessage(true)
          }
        }}
      >
        <div className="w-18 d-flex justify-content-center justify-content-md-start">
          <Avatar className="avatar">
            {message.type === "update" ? (
              <MdNotificationsNone color="gray" />
            ) : message.type === "personal" ? (
              <Envelope color="gray" />
            ) : (
              <CashStack color="gray" />
            )}
          </Avatar>
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              {!session.readMessages?.some(item => item.id === message.id) ? (
                <div className="dot">
                  <Dot
                    color="green"
                    style={{
                      marginTop: "-4px",
                      marginLeft: "-3px",
                    }}
                  />
                </div>
              ) : null}
              <h5>{message.title}</h5>
            </div>
            <p className="date">
              {moment(message?.date || message?.createdAt).fromNow()}
            </p>
          </div>
          <p className="text">
            {message.description.length > 75 && !showMessage
              ? `${message.description.substring(0, 75)}...`
              : message.description}
          </p>
        </div>
      </div>
    </>
  )
}

export default NotificationItem
